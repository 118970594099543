'use client'

import { useCitiesStore } from "@/store/citiesStore"
import { useMemo } from "react"

export function MinimalCitiesCard() {
  const { cities, homeCity } = useCitiesStore();

  const homeData = homeCity ? cities.get(homeCity) : null;

  // Create memoized array of other cities
  const otherCities = useMemo(() => {
    return Array.from(cities.values())
      .filter(city => city.locationKey !== homeCity);
  }, [cities, homeCity]);

  if (!homeCity || !homeData) {
    return null;
  }

  return (
    <div className="w-full max-w-md mx-auto mt-4 px-4">
      <div className="flex flex-wrap gap-2">
        {/* Home city pill */}
        <div className="flex items-center gap-2 px-3 py-1.5 rounded-full bg-gray-50">
          <span className="text-xl" aria-hidden="true">{homeData.emoji}</span>
          <span className="text-lg font-['SofiaSansRegular']">
            {homeData.city}
          </span>
        </div>

        {/* Other cities pills */}
        {otherCities.map((city) => (
          <div
            key={city.locationKey}
            className="flex items-center gap-2 px-3 py-1.5 rounded-full bg-gray-50"
          >
            <span className="text-lg" aria-hidden="true">{city.emoji}</span>
            <span className="text-base font-['SofiaSansRegular']">
              {city.city}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
} 