'use client';
import { useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { useCitiesStore } from '@/store/citiesStore';

// Utility to load last URL from localStorage
function getLastStoredUrl() {
  if (typeof window === 'undefined') return null;
  const url = localStorage.getItem('timeywimey_last_url');
  console.log('[StoreInitializer getLastStoredUrl]', { url });
  return url;
}

export default function StoreInitializer() {
  const router = useRouter();
  const pathname = usePathname();
  const isInitialized = useCitiesStore(state => state.isInitialized);
  const initialize = useCitiesStore(state => state.initialize);
  const attemptGeolocation = useCitiesStore(state => state.attemptGeolocation);
  const { cities, homeCity, isDefaultHomeCity } = useCitiesStore();

  // Handle store initialization
  useEffect(() => {
    if (isInitialized) {
      console.log('[StoreInitializer] Already initialized, skipping');
      return;
    }

    async function runInit() {
      console.log('[StoreInitializer runInit] Starting initialization', {
        pathname,
        isInitialized,
        hasCities: !!cities,
        hasHomeCity: !!homeCity
      });

      // If we're at '/' or '/cities', try to restore last URL from localStorage
      if (pathname === '/' || pathname === '/cities') {
        console.log('[StoreInitializer] At root path, checking localStorage');
        const lastUrl = getLastStoredUrl();
        console.log('[StoreInitializer] Last URL check result:', { lastUrl });

        if (lastUrl) {
          console.log('[StoreInitializer] Found last URL, restoring', { lastUrl });
          await router.replace(lastUrl);
          // Extract cities from the last URL and initialize with them
          const urlCities = lastUrl.replace('/cities/', '').split('/').filter(Boolean);
          console.log('[StoreInitializer] Initializing with cities from last URL', { urlCities });
          await initialize(urlCities);
          console.log('[StoreInitializer] Initialization with last URL cities complete');
          return;
        }
        console.log('[StoreInitializer] No last URL found, initializing with default cities');
        await initialize();
        console.log('[StoreInitializer] Default initialization complete, attempting geolocation');
        await attemptGeolocation(true);
        console.log('[StoreInitializer] Geolocation attempt complete');
        return;
      }

      // If we're on /cities/... parse slugs from pathname
      let urlCities: string[] | undefined;
      if (pathname.startsWith('/cities/')) {
        urlCities = pathname.replace('/cities/', '').split('/').filter(Boolean);
        console.log('[StoreInitializer] Parsed cities from URL', { urlCities, pathname });
      }

      console.log('[StoreInitializer] Initializing store', { urlCities });
      await initialize(urlCities);
      console.log('[StoreInitializer] Store initialized, attempting geolocation');
      await attemptGeolocation(false);
      console.log('[StoreInitializer] Geolocation attempt complete');
    }

    runInit();
  }, [isInitialized, pathname, initialize, attemptGeolocation, router, cities, homeCity]);

  // Handle URL synchronization
  useEffect(() => {
    if (!isInitialized || !cities || !homeCity) {
      console.log('[StoreInitializer URL Sync] Not ready', {
        isInitialized,
        hasCities: !!cities,
        hasHomeCity: !!homeCity,
        pathname
      });
      return;
    }

    // Always update URL to reflect current cities
    const cityKeys = Array.from(cities.keys());
    const nonHomeCities = cityKeys.filter(key => key !== homeCity);
    const newPath = `/cities/${homeCity}/${nonHomeCities.join('/')}`;

    console.log('[StoreInitializer URL Sync] Checking URL update', {
      currentPath: pathname,
      newPath,
      homeCity,
      otherCities: nonHomeCities,
      isDefaultHomeCity
    });

    // Always save the current path to localStorage
    localStorage.setItem('timeywimey_last_url', newPath);

    // Only update URL if different from current path
    if (pathname !== newPath) {
      console.log('[StoreInitializer URL Sync] Updating URL', { from: pathname, to: newPath });
      router.replace(newPath, { scroll: false });
    } else {
      console.log('[StoreInitializer URL Sync] No URL update needed');
    }
  }, [isInitialized, cities, homeCity, pathname, router, isDefaultHomeCity]);

  return null;
} 