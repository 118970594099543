import { create } from 'zustand';
import { enableMapSet } from 'immer';
import { immer } from 'zustand/middleware/immer';
import { TimezoneData } from '@/api/timezone/timezoneData';
import { initialCities } from './initialState';
import { CitiesState, CitiesActions, CityCommand } from './types';
import { reorderCities, fetchCityData, logCitiesState, getCityName, calculateCityPairQuality } from './utils';
import { generateLocationKey } from '@/utils/locationKey';
import { isSameCity } from '@/lib/cityUtils';

enableMapSet();

export const useCitiesStore = create<CitiesState & CitiesActions>()(
  immer((set, get) => {
    const initialState = {
      cities: new Map(),
      homeCity: null,
      isDefaultHomeCity: false,
      hasAttemptedGeolocation: false,
      isHydrated: true,
      geolocatedCity: null,
      isUsingInitialCities: true,
      isInitialized: false
    };

    const createCityFromTimezoneData = (timezoneData: TimezoneData | null): TimezoneData | null => {
      if (!timezoneData || !timezoneData.timezone || !timezoneData.country || !timezoneData.emoji) {
        console.error('Invalid timezone data:', timezoneData);
        return null;
      }

      const locationKey = generateLocationKey(
        timezoneData.country,
        timezoneData.city,
        timezoneData.state
      );
      const cityName = getCityName(timezoneData);

      return {
        city: cityName,
        timezone: timezoneData.timezone,
        emoji: timezoneData.emoji,
        abbreviation: timezoneData.abbreviation || cityName,
        country: timezoneData.country,
        state: timezoneData.state,
        locationKey,
      };
    };

    function handleInitialCitiesCase(newCity: TimezoneData, setAsHome: boolean) {
      const state = get();
      const [city1, city2] = Array.from(state.cities.values());

      console.log('[CitiesStore] Evaluating initial cities with geolocated city:', {
        geolocatedCity: {
          name: newCity.city,
          timezone: newCity.timezone,
          locationKey: newCity.locationKey,
          willBeHome: setAsHome
        },
        initialCity1: {
          name: city1.city,
          timezone: city1.timezone,
          locationKey: city1.locationKey
        },
        initialCity2: {
          name: city2.city,
          timezone: city2.timezone,
          locationKey: city2.locationKey
        }
      });

      // Calculate quality scores with geolocated city as home
      const city1Score = calculateCityPairQuality(newCity, city1);
      const city2Score = calculateCityPairQuality(newCity, city2);

      console.log('[CitiesStore] City pair quality scores:', {
        withCity1: {
          cities: `${newCity.city} & ${city1.city}`,
          score: city1Score
        },
        withCity2: {
          cities: `${newCity.city} & ${city2.city}`,
          score: city2Score
        }
      });

      // Keep the initial city that makes the better pair with the geolocated city
      if (city1Score >= city2Score) {
        console.log('[CitiesStore] Decision: Keeping first initial city', {
          keeping: city1.city,
          removing: city2.city,
          newHome: setAsHome ? newCity.city : city1.city
        });
        get().removeCity(city2.locationKey);
      } else {
        console.log('[CitiesStore] Decision: Keeping second initial city', {
          keeping: city2.city,
          removing: city1.city,
          newHome: setAsHome ? newCity.city : city2.city
        });
        get().removeCity(city1.locationKey);
      }

      // Add the geolocated city
      get().addCityWithTimezoneData(newCity, setAsHome);
    }

    const _handleGeolocationAdd = (newCity: TimezoneData, setAsHome: boolean) => {
      const state = get();

      console.log('[CitiesStore] Processing geolocated city:', {
        city: newCity.city,
        timezone: newCity.timezone,
        setAsHome,
        currentState: {
          isUsingInitialCities: state.isUsingInitialCities,
          cityCount: state.cities.size,
          isDefaultHomeCity: state.isDefaultHomeCity
        }
      });

      // Check if this city would be a duplicate
      const existingCity = Array.from(state.cities.values()).find(city =>
        isSameCity(city, newCity)
      );

      if (existingCity) {
        console.log('[CitiesStore] Found duplicate city:', {
          existing: {
            name: existingCity.city,
            timezone: existingCity.timezone,
            locationKey: existingCity.locationKey
          },
          action: setAsHome || state.isDefaultHomeCity ? 'updating home city' : 'no action needed'
        });

        // If it's a duplicate, just update home city if needed
        if (setAsHome || state.isDefaultHomeCity) {
          get().setHomeCity(existingCity.locationKey);
        }
      } else if (state.isUsingInitialCities && state.cities.size === 2) {
        console.log('[CitiesStore] Using initial cities setup, evaluating optimal distribution');
        // Special case: Adding a unique geolocated city while using initial cities
        // Always set as home in this case
        handleInitialCitiesCase(newCity, true);
      } else {
        console.log('[CitiesStore] Normal case: Adding new city', {
          city: newCity.city,
          setAsHome: setAsHome || state.isDefaultHomeCity
        });
        // Normal case: Just add the city
        get().addCityWithTimezoneData(newCity, setAsHome || state.isDefaultHomeCity);
      }

      // No longer using initial cities after attempting geolocation
      set(state => {
        state.isUsingInitialCities = false;
        state.isDefaultHomeCity = false;
      });
    };

    return {
      ...initialState,

      // New action: initialize store with either URL or default
      initialize: async (urlCities?: string[]) => {
        console.log('[CitiesStore initialize] Starting initialization', {
          urlCities,
          isInitialized: get().isInitialized
        });

        const state = get();
        if (state.isInitialized) {
          console.log('[CitiesStore initialize] Already initialized, skipping');
          return;
        }

        // Decide if we have URL-based cities
        if (urlCities && urlCities.length > 0) {
          console.log('[CitiesStore initialize] Processing URL cities', { urlCities });
          // Example: load data for each city or fallback
          const citiesMap = new Map<string, TimezoneData>();
          for (const slug of urlCities) {
            console.log('[CitiesStore initialize] Fetching city data', { slug });
            const cityData = await fetchCityData(slug);
            if (cityData) {
              console.log('[CitiesStore initialize] Adding city from URL', { cityData });
              citiesMap.set(slug, cityData);
            }
          }
          if (citiesMap.size > 0) {
            const slugs = Array.from(citiesMap.keys());
            console.log('[CitiesStore initialize] Setting URL-based cities', {
              cities: Array.from(citiesMap.entries()),
              homeCity: slugs[0]
            });
            set(s => {
              s.cities = reorderCities(citiesMap, slugs[0]);
              s.homeCity = slugs[0];
              s.isDefaultHomeCity = false;
              s.isUsingInitialCities = false;
              s.isInitialized = true;
            });
            return;
          }
          console.log('[CitiesStore initialize] No valid cities from URL, falling back to defaults');
        }

        // Otherwise use initial cities
        console.log('[CitiesStore initialize] Using initial cities');
        const homeCity = Array.from(initialCities.keys())[0];
        set(s => {
          s.cities = reorderCities(initialCities, homeCity);
          s.homeCity = homeCity;
          s.isDefaultHomeCity = true;
          s.isUsingInitialCities = true;
          s.isInitialized = true;
        });

        console.log('[CitiesStore initialize] Initialization complete', {
          state: get()
        });
      },

      addCity: (city: TimezoneData) => {
        console.log('[CitiesStore addCity] Adding city:', { city });
        set(state => {
          state.cities.set(city.locationKey, city);
          state.cities = reorderCities(state.cities, state.homeCity || city.locationKey);
          if (!state.homeCity) {
            state.homeCity = city.locationKey;
          }
          logCitiesState('After addCity', state.cities, state.homeCity);
        });
      },

      addCityByAddress: async (address: string, setAsHome = false) => {
        try {
          const response = await fetch('/api/timezone', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ address }),
          });

          if (!response.ok) {
            console.error('Failed to add city:', await response.text());
            return null;
          }

          const timezoneData = await response.json() as TimezoneData;
          get().addCityWithTimezoneData(timezoneData, setAsHome);
          return timezoneData;
        } catch (error) {
          console.error('Error adding city:', error);
          return null;
        }
      },

      addCityWithTimezoneData: (timezoneData: TimezoneData, setAsHome = false) => {
        console.log('[CitiesStore addCityWithTimezoneData] Adding city:', {
          timezoneData,
          setAsHome,
          existingCities: Array.from(get().cities.keys())
        });

        if (!timezoneData.country || !timezoneData.timezone || !timezoneData.emoji) {
          console.error('[CitiesStore] Invalid timezone data:', timezoneData);
          return;
        }

        // Check for duplicates using the new isSameCity function
        const existingCity = Array.from(get().cities.values()).find(city =>
          isSameCity(city, timezoneData)
        );

        if (existingCity) {
          console.log('[CitiesStore] City already exists:', {
            existingCity,
            timezoneData
          });
          // If this is meant to be home city, update that
          if (setAsHome) {
            set(state => {
              state.homeCity = existingCity.locationKey;
              state.isDefaultHomeCity = false;
              state.cities = reorderCities(state.cities, existingCity.locationKey);
            });
          }
          return;
        }

        const locationKey = generateLocationKey(
          timezoneData.country,
          timezoneData.city,
          timezoneData.state
        );

        const cityName = getCityName(timezoneData);

        const newCity: TimezoneData = {
          city: cityName,
          timezone: timezoneData.timezone,
          emoji: timezoneData.emoji,
          abbreviation: timezoneData.abbreviation || cityName,
          country: timezoneData.country,
          state: timezoneData.state,
          locationKey,
        };

        console.log('[CitiesStore] Created new city:', newCity);

        const state = get();
        if (state.isUsingInitialCities && state.cities.size === 2) {
          // Handle optimization case
          console.log('[CitiesStore] Using initial cities setup, evaluating optimal distribution');
          handleInitialCitiesCase(newCity, setAsHome);
        } else {
          // Normal case: Just add the city
          set(state => {
            state.cities.set(locationKey, newCity);
            state.cities = reorderCities(state.cities, setAsHome ? locationKey : state.homeCity);
            if (setAsHome || !state.homeCity) {
              state.homeCity = locationKey;
              state.isDefaultHomeCity = false;
            }
            logCitiesState('After addCityWithTimezoneData', state.cities, state.homeCity);
          });
        }
      },

      removeCity: (locationKey: string) => set(state => {
        state.cities.delete(locationKey);
        const citiesArray = Array.from(state.cities.keys());
        if (state.homeCity === locationKey) {
          state.homeCity = citiesArray[0] || null;
        }
        state.cities = reorderCities(state.cities, state.homeCity);
        state.isDefaultHomeCity = false;
      }),

      setHomeCity: (locationKey: string) => set(state => {
        state.homeCity = locationKey;
        state.cities = reorderCities(state.cities, locationKey);
        state.isDefaultHomeCity = false;
      }),

      setHasAttemptedGeolocation: (value: boolean) => set(state => {
        state.hasAttemptedGeolocation = value;
      }),

      attemptGeolocation: async (setAsHome = false) => {
        const state = get();
        if (!state.isInitialized) return;

        try {
          // Get geolocation and timezone data
          const response = await fetch('/api/geolocation');
          if (!response.ok) throw new Error('Failed to get geolocation data');

          const timezoneData = await response.json();
          const newCity = createCityFromTimezoneData(timezoneData);
          if (!newCity) return;

          // Store the geolocated city first
          set(state => {
            state.geolocatedCity = newCity;
            state.hasAttemptedGeolocation = true;
          });

          // Only optimize pairs when landing on root with initial cities
          if (state.isUsingInitialCities && state.cities.size === 2) {
            handleInitialCitiesCase(newCity, true);
          }

        } catch (error) {
          console.error('Error in geolocation process:', error);
        } finally {
          set(state => {
            state.hasAttemptedGeolocation = true;
          });
        }
      },

      addLocalCity: async () => {
        const state = get();
        if (!state.geolocatedCity) return;

        // Simply add the geolocated city and make it home
        get().addCityWithTimezoneData(state.geolocatedCity, true);
      },

      processCommands: async (commands: CityCommand[]) => {
        // Sort commands by type to ensure correct execution order
        const removeCommands = commands.filter(cmd => cmd.type === 'REMOVE');
        const addCommands = commands.filter(cmd => cmd.type === 'ADD');
        const setHomeCommands = commands.filter(cmd => cmd.type === 'SET_HOME');

        // Process REMOVE commands first
        for (const command of removeCommands) {
          if (command.locationKey && get().cities.has(command.locationKey)) {
            get().removeCity(command.locationKey);
          }
        }

        // Process ADD commands next
        for (const command of addCommands) {
          if (command.timezoneData) {
            get().addCityWithTimezoneData(command.timezoneData);
          }
        }

        // Process SET_HOME commands last
        for (const command of setHomeCommands) {
          if (command.locationKey && get().cities.has(command.locationKey)) {
            get().setHomeCity(command.locationKey);
          }
        }
      },
    };
  })
);

