import { TimezoneData } from '@/api/timezone/timezoneData';

/**
 * Calculate the timezone difference between two timezones
 */
export function getTimezoneDiff(timezone1: string, timezone2: string): number {
  const now = new Date();
  const time1 = new Date(now.toLocaleString('en-US', { timeZone: timezone1 }));
  const time2 = new Date(now.toLocaleString('en-US', { timeZone: timezone2 }));
  let diff = (time1.getTime() - time2.getTime()) / (1000 * 60 * 60);

  // Normalize to -12 to +12 range
  if (diff <= -12) diff += 24;
  if (diff > 12) diff -= 24;
  return diff;
}

/**
 * Reorder cities based on their timezone difference from the home city
 */
export function reorderCities(cities: Map<string, TimezoneData>, homeCity: string | null): Map<string, TimezoneData> {
  if (!homeCity || !cities.has(homeCity)) {
    return cities;
  }

  const homeTimezone = cities.get(homeCity)!.timezone;

  const citiesList = Array.from(cities.values()).sort((a, b) => {
    if (a.locationKey === homeCity) return -1;
    if (b.locationKey === homeCity) return 1;

    let diffA = getTimezoneDiff(a.timezone, homeTimezone);
    if (diffA < 0) diffA += 24;

    let diffB = getTimezoneDiff(b.timezone, homeTimezone);
    if (diffB < 0) diffB += 24;

    return diffA - diffB;
  });

  return new Map(citiesList.map(city => [city.locationKey, city]));
}

/**
 * Parse cities from the URL path
 * Format: /cities/city1-country1/city2-country2/...
 */
export function parseUrlCities(): string[] {
  if (typeof window === 'undefined') {
    console.log('URL Parsing: Server-side, skipping');
    return [];
  }

  const pathname = window.location.pathname;
  if (!pathname.startsWith('/cities/')) {
    console.log('URL Parsing: Not a cities URL:', pathname);
    return [];
  }

  const cityKeys = pathname
    .replace('/cities/', '')
    .split('/')
    .filter(Boolean)
    .map(key => {
      const decodedKey = decodeURIComponent(key.toLowerCase());
      const parts = decodedKey.split('-');

      // If it's a country-only key (e.g., "kw"), return as is
      if (parts.length === 1) return decodedKey;

      // If it's a city without state (e.g., "london-gb"), we need to fetch state info
      if (parts.length === 2) {
        const [city, country] = parts;
        // For now, return the key as is - the city API will handle getting state info
        return `${city}-${country}`;
      }

      // If it's already in the new format (city-state-country), return as is
      return decodedKey;
    });

  console.log('URL Parsing: Found cities in URL:', cityKeys);
  return cityKeys;
}

/**
 * Fetch city data from the API
 */
export async function fetchCityData(locationKey: string): Promise<TimezoneData | null> {
  try {
    const response = await fetch('/api/city', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ locationKey }),
    });

    if (!response.ok) {
      console.error('Failed to fetch city data:', await response.text());
      return null;
    }

    const data = await response.json();
    return data as TimezoneData;
  } catch (error) {
    console.error('Error fetching city data:', error);
    return null;
  }
}

/**
 * Debug logging function for cities state
 */
export function logCitiesState(prefix: string, cities: Map<string, TimezoneData>, homeCity: string | null) {
  console.log(`[CitiesStore ${prefix}]`, {
    cities: Array.from(cities.entries()).map(([key, city]) => ({
      key,
      name: city.city,
      country: city.country,
      state: city.state,
      locationKey: city.locationKey
    })),
    homeCity,
    totalCities: cities.size
  });
}

/**
 * Get the display name for a city
 * If city name is available, use that
 * Otherwise use abbreviation if it's different from country code
 * Finally fall back to country name
 */
export function getCityName(timezoneData: TimezoneData): string {
  return timezoneData.city || (
    timezoneData.abbreviation &&
      timezoneData.abbreviation.toUpperCase() !== timezoneData.country
      ? timezoneData.abbreviation
      : timezoneData.country
  );
}

/**
 * Calculate a quality score for a pair of cities based on their timezone differences.
 * Returns a score between 0 and 1, where:
 * - 0 means the cities are in the same timezone (worst case)
 * - 1 means the cities are optimally spaced (around 8-12 hours apart)
 */
export function calculateCityPairQuality(city1: TimezoneData, city2: TimezoneData): number {
  const diff = Math.abs(getTimezoneDiff(city1.timezone, city2.timezone));

  // If cities are in the same timezone, that's the worst case
  if (diff === 0) return 0;

  // Optimal difference is between 8-12 hours
  // Less than 4 hours or more than 16 hours is suboptimal
  if (diff >= 8 && diff <= 12) return 1;

  // Scale the score based on how close it is to the optimal range
  if (diff < 8) {
    return diff / 8;
  } else {
    return (24 - diff) / 12;
  }
}