'use client';

import React, { useEffect, useState } from 'react';
import { useClockSettingsStore } from '@/store/timeStore';
import { getTimeCategory, TIME_COLORS } from '@/lib/meetingUtils';
import { TimezoneData } from '@/api/timezone/timezoneData';

interface ClockCityProps {
  cities: TimezoneData[];
  angle: number;
  baseTime: Date;
  homeCity: string | null;
  radius: number;
  hideLabel?: boolean;
}

interface GroupedCity {
  name: string;
  isHome: boolean;
  locationKey: string;
}

interface TimeGroup {
  time: string;
  cities: GroupedCity[];
}

interface GroupedTimeMap {
  [key: string]: TimeGroup;
}

const EMOJI_CLASSES = 'flex items-center space-x-0.5';

function roundPosition(value: number): string {
  return value.toFixed(3);
}

function deduplicateEmojis(cities: TimezoneData[]): Array<{ emoji: string, locationKey: string }> {
  const uniqueEmojisMap = new Map<string, { emoji: string, locationKey: string }>();

  cities.forEach(city => {
    if (city.emoji && city.locationKey) {
      if (!uniqueEmojisMap.has(city.emoji)) {
        uniqueEmojisMap.set(city.emoji, { emoji: city.emoji, locationKey: city.locationKey });
      }
    }
  });

  return Array.from(uniqueEmojisMap.values());
}

function getTranslations(angle: number) {
  const radians = (angle - 90) * (Math.PI / 180);
  const translateX = -75 * Math.cos(radians);
  const translateY = -75 * Math.sin(radians);
  return { translateX, translateY };
}

function getTimeForCity(timezone: string, baseTime: Date): Date {
  return new Date(baseTime.toLocaleString('en-US', { timeZone: timezone }));
}

function roundAngle(angle: number): number {
  return Number(angle.toFixed(2));
}

export function CityClock({ cities, angle, baseTime, homeCity, radius, hideLabel = false }: ClockCityProps) {
  // Validate that all cities have required properties
  const invalidCities = cities.filter(city => !city.locationKey || !city.emoji);
  if (invalidCities.length > 0) {
    console.error('Found invalid cities:', invalidCities);
  }

  const roundedAngle = roundAngle(angle);
  const radians = (roundedAngle + 90) * (Math.PI / 180);
  const dotX = 50 + radius * Math.cos(radians);
  const dotY = 50 + radius * Math.sin(radians);

  const [isMounted, setIsMounted] = useState(false);

  const emojis = deduplicateEmojis(cities);

  // Validate emoji array
  const invalidEmojis = emojis.filter(e => !e.locationKey);
  if (invalidEmojis.length > 0) {
    console.error('Found emojis without locationKeys:', invalidEmojis);
  }

  const isHome = cities.some(city => city.locationKey === homeCity);

  const timeFormat = useClockSettingsStore(state => state.timeFormat);

  // Get the time category for the first city (they should all be in the same hour)
  const cityTime = getTimeForCity(cities[0].timezone, baseTime);
  const timeCategory = getTimeCategory(cityTime.getHours());
  const backgroundColor = TIME_COLORS[timeCategory];

  // Calculate the natural position for home city (without offset)
  const naturalTime = getTimeForCity(cities[0].timezone, new Date());
  const naturalHours = naturalTime.getHours();
  const naturalMinutes = naturalTime.getMinutes();
  const naturalAngle = ((naturalHours + naturalMinutes / 60) / 24) * 360;
  const naturalRadians = (naturalAngle + 90) * (Math.PI / 180);
  const naturalX = 50 + radius * Math.cos(naturalRadians);
  const naturalY = 50 + radius * Math.sin(naturalRadians);

  // Calculate the shortest path between the two angles
  const angleDiff = ((roundedAngle - naturalAngle + 540) % 360) - 180;
  const shouldTakeShortPath = Math.abs(angleDiff) <= 180;
  const sweepFlag = angleDiff > 0;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const translations = getTranslations(roundedAngle);

  // Group cities by time
  const groupedByTime = cities.reduce((groups: GroupedTimeMap, city) => {
    const cityTime = getTimeForCity(city.timezone, baseTime);
    const isHomeCity = city.locationKey === homeCity;
    const timeString = cityTime.toLocaleTimeString('en-US', {
      ...timeFormat,
      second: isHomeCity ? 'numeric' : undefined
    });

    if (!groups[timeString]) {
      groups[timeString] = {
        time: timeString,
        cities: []
      };
    }

    groups[timeString].cities.push({
      name: city.abbreviation ?? city.city,
      isHome: isHomeCity,
      locationKey: city.locationKey
    });

    return groups;
  }, {});

  return (
    <>
      {/* Trail Arc for Home City */}
      {isHome && (
        <div
          className="absolute inset-0"
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            zIndex: 20,
          }}
        >
          <svg className="w-full h-full" viewBox="0 0 100 100" style={{ overflow: 'visible' }}>
            <path
              d={`
                M ${naturalX} ${naturalY}
                A ${radius} ${radius} 0 ${!shouldTakeShortPath ? 1 : 0} ${sweepFlag ? 1 : 0} ${dotX} ${dotY}
              `}
              fill="none"
              stroke="rgba(37, 99, 235, 0.4)"
              strokeWidth="1"
              strokeDasharray="1.5 1"
            />
          </svg>
        </div>
      )}

      {/* Emoji Pill */}
      <div
        className={`${EMOJI_CLASSES} rounded-full shadow px-1.5 py-0.5 ${isHome ? 'ring-[3px] ring-blue-600' : ''} z-30`}
        style={{
          position: 'absolute',
          left: `${roundPosition(dotX)}%`,
          top: `${roundPosition(dotY)}%`,
          transform: `translate(-50%, -50%) rotate(${roundedAngle}deg)`,
          backgroundColor,
          opacity: 0.9,
          zIndex: 30,
        }}
      >
        {[...emojis].reverse().map(({ emoji, locationKey }) => {
          const isHomeEmoji = cities.find(city => city.locationKey === locationKey)?.locationKey === homeCity;
          return (
            <span
              key={locationKey}
              className={`${isHomeEmoji ? 'text-xl sm:text-2xl md:text-3xl lg:text-4xl' : 'text-lg sm:text-xl md:text-2xl lg:text-3xl'}`}
              style={{
                transform: `rotate(${-roundedAngle}deg)`,
                lineHeight: 1,
              }}
            >
              {emoji}
            </span>
          );
        })}
      </div>

      {/* Text */}
      {!hideLabel && isMounted && (
        <div
          className="absolute text-xs sm:text-sm md:text-base lg:text-lg max-w-full break-words z-50"
          style={{
            left: `${roundPosition(dotX)}%`,
            top: `${roundPosition(dotY)}%`,
            transform: `translate(-50%, -50%) translate(${translations.translateX}%, ${translations.translateY}%)`,
          }}
        >
          <div className="rounded-md bg-popover/75 shadow-md backdrop-blur-[2px]">
            <div className="text-center px-1.5 py-1">
              {Object.values(groupedByTime).reverse().map((group) => (
                <div key={`time-${group.time}`}>
                  {group.cities.map((city, index) => (
                    <div
                      key={`city-${city.locationKey}`}
                      className={`${city.isHome ? 'text-lg sm:text-base md:text-lg lg:text-xl font-semibold text-blue-600' : 'text-base sm:text-sm md:text-base lg:text-lg font-normal text-gray-500'} font-['SofiaSansCity'] leading-tight 
                          ${index !== group.cities.length - 1 ? 'mb-0.5' : ''}`}
                    >
                      {city.name}
                    </div>
                  ))}
                  <div
                    className={`text-xl sm:text-base md:text-lg lg:text-xl font-['SofiaSansTime'] whitespace-nowrap leading-none 
                        ${group.cities.some(c => c.isHome) ? 'text-blue-800 font-medium' : 'text-gray-900'}`}
                  >
                    {group.time}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}