'use client';

import React from 'react';
import { ClockBackground } from './ClockBackground';
import { CityClocks } from './CityClocks';
import { useDragTime } from '@hooks/useDragTime';

export const TW_CLOCK_SIZE = `
  aspect-square
  w-full
  max-w-[min(70vw,70vh)]
  md:max-w-[min(60vw,60vh)]
  lg:max-w-[min(50vw,50vh)]
  xl:max-w-[500px]
`

interface ClockProps {
  ClockBackgroundComponent?: React.ReactNode;
}

const Clock: React.FC<ClockProps> = ({ ClockBackgroundComponent }) => {
  const { isDragging, eventHandlers } = useDragTime();

  return (
    <div className="w-full flex justify-center my-12 landscape:my-8 px-8">
      <div className={`relative ${TW_CLOCK_SIZE}`} style={{ touchAction: 'none' }}>
        <div
          id="clock-container"
          className="absolute inset-0 cursor-grab"
          onMouseDown={eventHandlers.onMouseDown}
          onTouchStart={eventHandlers.onTouchStart}
          style={{
            cursor: isDragging ? 'grabbing' : 'grab',
            touchAction: 'none'
          }}
        >
          {ClockBackgroundComponent || (
            <div
              className="absolute inset-0"
              style={{
                filter: "drop-shadow(0em 0.4em 0.4em rgba(112, 100, 93, 0.5))",
              }}
            >
              <ClockBackground />
            </div>
          )}
        </div>
        <div className="absolute inset-0">
          <CityClocks />
        </div>
      </div>
    </div>
  );
};

export default Clock;
