"use client";

import { useClockSettingsStore } from "@/store/timeStore";
import { trackEvent } from "@/lib/analytics";

// Custom icons components
const ClockIcon = ({ isSelected }: { isSelected: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 0.25C4.36522 0.25 0 4.61522 0 10C0 15.3848 4.36522 19.75 9.75 19.75C15.1348 19.75 19.5 15.3848 19.5 10C19.5 4.61522 15.1348 0.25 9.75 0.25ZM10.5 4C10.5 3.58579 10.1642 3.25 9.75 3.25C9.33579 3.25 9 3.58579 9 4V10C9 10.4142 9.33579 10.75 9.75 10.75H14.25C14.6642 10.75 15 10.4142 15 10C15 9.58579 14.6642 9.25 14.25 9.25H10.5V4Z"
      fill={isSelected ? "#0F172A" : "#777777"}
    />
  </svg>
);

const CalendarIcon = ({ isSelected }: { isSelected: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6.75 3V5.25M17.25 3V5.25M3 18.75V7.5C3 6.90326 3.23705 6.33097 3.65901 5.90901C4.08097 5.48705 4.65326 5.25 5.25 5.25H18.75C19.3467 5.25 19.919 5.48705 20.341 5.90901C20.7629 6.33097 21 6.90326 21 7.5V18.75M3 18.75C3 19.3467 3.23705 19.919 3.65901 20.341C4.08097 20.7629 4.65326 21 5.25 21H18.75C19.3467 21 19.919 20.7629 20.341 20.341C20.7629 19.919 21 19.3467 21 18.75M3 18.75V11.25C3 10.6533 3.23705 10.081 3.65901 9.65901C4.08097 9.23705 4.65326 9 5.25 9H18.75C19.3467 9 19.919 9.23705 20.341 9.65901C20.7629 10.081 21 10.6533 21 11.25V18.75M12 12.75H12.008V12.758H12V12.75ZM12 15H12.008V15.008H12V15ZM12 17.25H12.008V17.258H12V17.25ZM9.75 15H9.758V15.008H9.75V15ZM9.75 17.25H9.758V17.258H9.75V17.25ZM7.5 15H7.508V15.008H7.5V15ZM7.5 17.25H7.508V17.258H7.5V17.25ZM14.25 12.75H14.258V12.758H14.25V12.75ZM14.25 15H14.258V15.008H14.25V15ZM14.25 17.25H14.258V17.258H14.25V17.25ZM16.5 12.75H16.508V12.758H16.5V12.75ZM16.5 15H16.508V15.008H16.5V15Z"
      stroke={isSelected ? "#0F172A" : "#777777"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function ClockSwitcher() {
  const { clockMode, setClockMode } = useClockSettingsStore();

  const handleToggle = () => {
    const newMode = clockMode === "clock" ? "schedule" : "clock";
    trackEvent.clockModeChanged(newMode);
    setClockMode(newMode);
  };

  const isClockMode = clockMode === "clock";

  return (
    <div className="inline-flex items-center bg-gray-200 rounded-full p-1" style={{ width: '95.5px', padding: '0.25rem' }}>
      <button
        onClick={handleToggle}
        className="relative flex items-center w-full"
        style={{ height: '40px' }}
        aria-label="Toggle between calendar and clock view"
      >
        <div
          className="absolute flex items-center justify-center bg-white"
          style={{
            borderRadius: '38px',
            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
            width: '35.5px',
            height: '35.5px',
            left: '2.5px',
            transform: isClockMode ? 'translateX(47.5px)' : 'translateX(0)',
            transition: 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
          }}
        />
        <div className="relative flex items-center justify-between w-full" style={{ paddingLeft: '10px', paddingRight: '8px' }}>
          <div className="flex items-center justify-center w-6">
            <CalendarIcon isSelected={!isClockMode} />
          </div>
          <div className="flex items-center justify-center w-6">
            <ClockIcon isSelected={isClockMode} />
          </div>
        </div>
      </button>
    </div>
  );
}

export default ClockSwitcher;