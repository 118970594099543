'use client';

import React, { forwardRef } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { Autocomplete, AutocompleteInput, AutocompleteList, AutocompleteItem } from '@components/ui/autocomplete';
import { useCitiesStore } from '@/store/citiesStore';
import { trackEvent } from '@/lib/analytics';

const CityAutocomplete = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const { addCityByAddress, cities } = useCitiesStore();

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['country', 'locality'],
    },
    debounce: 300,
  });

  const handleSelect = async (address: string) => {
    console.log('City selected:', address);
    setValue(address, false);
    clearSuggestions();
    const isFirstCity = cities.size === 0;
    const city = await addCityByAddress(address, isFirstCity);
    if (city?.locationKey) {
      trackEvent.cityAdded(city.locationKey);
    }
    setValue('');
  };

  return (
    <Autocomplete className="transition-all duration-150 ease-in-out">
      <AutocompleteInput
        {...props}
        ref={ref}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
      />
      {status === 'OK' && (
        <AutocompleteList>
          {data.map(({ place_id, description }) => (
            <AutocompleteItem key={place_id} onClick={() => handleSelect(description)}>
              {description}
            </AutocompleteItem>
          ))}
        </AutocompleteList>
      )}
    </Autocomplete>
  );
});

export default CityAutocomplete;

// Set display name for the component
CityAutocomplete.displayName = 'CityAutocomplete';