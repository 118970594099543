'use client'
import { CirclePlus } from 'lucide-react';
import CityAutocomplete from "./CityAutocomplete";
import { useRef } from 'react';
import { useCitiesStore } from '@/store/citiesStore';
import { useClockSettingsStore } from '@/store/timeStore';
import VoiceInput from './VoiceInput';

export default function CitySearch() {
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const { cities } = useCitiesStore();
  const { clockMode } = useClockSettingsStore();

  const isSimpleMode = clockMode === "clock";
  const buttonStyle = isSimpleMode
    ? "bg-transparent text-gray-600 border-2 border-gray-400 hover:bg-gray-50 placeholder:text-gray-600"
    : "bg-blue-500 text-white hover:opacity-90 placeholder:text-white";
  const focusStyle = isSimpleMode
    ? "focus:ring-gray-400 focus:border-gray-400 focus:text-gray-600"
    : "focus:ring-blue-500 focus:border-blue-500 focus:text-black";

  return (
    <div className={`relative group focus-within:text-${isSimpleMode ? 'gray-600' : 'blue-500'} w-full max-w-md mx-auto`}>
      <CityAutocomplete
        ref={autocompleteRef}
        className={`h-12 px-4 py-2 pl-10 pr-12 rounded-full ${buttonStyle} focus:bg-white focus:ring-2 ${focusStyle} transition-all duration-200 font-['SofiaSansRegular'] text-[16px]`}
        placeholder="Add City"
      />
      <CirclePlus
        className={`absolute left-3 top-1/2 -translate-y-1/2 ${isSimpleMode ? 'text-gray-600' : 'text-white'} group-focus-within:text-${isSimpleMode ? 'gray-600' : 'blue-500'} pointer-events-none transition-colors duration-200`}
        size={20}
      />
      <VoiceInput cities={cities} isSimpleMode={isSimpleMode} />
    </div>
  );
} 
