import { TimezoneData } from "@/api/timezone/timezoneData";

/**
 * Checks if two cities are considered the same based on:
 * 1. Exact locationKey match, OR
 * 2. Same city name and country (case-insensitive)
 */
export function isSameCity(city1: TimezoneData, city2: TimezoneData): boolean {
  // If we have exact locationKey match
  if (city1.locationKey === city2.locationKey) {
    return true;
  }

  // Compare city name and country (ignore state as it might not always be available)
  return (
    city1.city?.toLowerCase() === city2.city?.toLowerCase() &&
    city1.country.toLowerCase() === city2.country.toLowerCase()
  );
} 