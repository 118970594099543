'use client'
import React from 'react';
import ResetOffsetButton from '@/components/MainClockControls';
import { Analytics } from '@vercel/analytics/react';
import TimeFormatToggle from '@components/MainClockControls/TimeFormatToggle';
import LogRocket from 'logrocket';
import Clock from '@components/Clock';
import { CreateMeetingCard } from '@components/Meeting/create-meeting-card';
import { MinimalCitiesCard } from '@components/Meeting/minimal-cities-card';
import CitySearch from '@components/CityManagement/CitySearch';
import ClockSwitcher from '@components/ClockSwitcher';
import { useClockSettingsStore } from '@store/timeStore';
import GradientClock from '@components/Clock/GradientClock';
import FeedbackModal from '@components/Feedback/FeedbackModal';

// Initialize LogRocket
if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
  LogRocket.init('98k6pd/timeywimey');
  console.log('LogRocket initialized in production');
} else {
  console.log('LogRocket not initialized (development mode)');
}

const Feedback = () => {
  return (
    <div className="py-4 w-full flex justify-center items-center">
      <FeedbackModal />
    </div>
  )
}

export default function Home() {
  const { clockMode } = useClockSettingsStore();

  const SelectedClock = clockMode === "clock" ? Clock : GradientClock;
  const SelectedCard = clockMode === "clock" ? MinimalCitiesCard : CreateMeetingCard;

  return (
    <>
      <main className="min-h-screen flex flex-col landscape:flex-row landscape:h-screen landscape:overflow-hidden">
        {/* Header - Full width in landscape */}
        <div className="w-full mt-2 landscape:fixed landscape:top-0 landscape:z-10 landscape:bg-white/80 landscape:backdrop-blur-sm">
          <div className="w-full h-14 flex justify-between items-center px-4 text-blue-500">
            <ClockSwitcher />
            <ResetOffsetButton />
            <TimeFormatToggle />
          </div>
        </div>

        {/* Left column in landscape / Top section in portrait */}
        <div className="w-full landscape:w-1/2 landscape:h-screen landscape:pt-12">
          <SelectedClock />
          <div className="px-4 pt-4 flex justify-center">
            <CitySearch />
          </div>
          <div className="landscape:hidden px-4 pb-4">
            <SelectedCard />
            <Feedback />
          </div>
        </div>

        {/* Right column in landscape / Bottom section in portrait */}
        <div className="w-full landscape:w-1/2 landscape:h-screen landscape:overflow-y-auto landscape:pt-16">
          <div className="hidden landscape:block w-full px-4 pb-4">
            <SelectedCard />
            <Feedback />
          </div>
        </div>
      </main>
      <Analytics />
    </>
  );
}
