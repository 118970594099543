import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

let isInitialized = false;

// Define gtag function type
declare global {
  interface Window {
    gtag: (
      command: 'event',
      eventName: string,
      eventParameters?: Record<string, unknown>
    ) => void;
    dataLayer: unknown[];
  }
}

// Initialize Mixpanel with your project token
export function initAnalytics() {
  // Skip completely in development mode
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!isInitialized && typeof window !== 'undefined' && MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
      ignore_dnt: true,
      api_host: '/mp',
    });
    isInitialized = true;
    console.log('Mixpanel initialized in production');
  }
}

// Google Analytics event tracking
function trackGoogleAnalytics(eventName: string, properties?: Record<string, unknown>) {
  if (typeof window !== 'undefined' && GA_MEASUREMENT_ID && window.gtag) {
    window.gtag('event', eventName, properties);
  }
}

// Safe tracking wrapper
const safeTrack = (eventName: string, properties?: Record<string, unknown>) => {
  if (!isInitialized) {
    initAnalytics();
  }

  // Track in Mixpanel only in production
  if (isInitialized && typeof window !== 'undefined' && mixpanel && process.env.NODE_ENV === 'production') {
    mixpanel.track(eventName, properties);
  }

  // Track in Google Analytics
  trackGoogleAnalytics(eventName, properties);
};

// Custom event tracking functions
export const trackEvent = {
  cityAdded: (locationKey: string) => {
    safeTrack('City Added', { locationKey });
  },
  cityRemoved: (locationKey: string) => {
    safeTrack('City Removed', { locationKey });
  },
  homeCitySet: (locationKey: string) => {
    safeTrack('Home City Set', { locationKey });
  },
  timeFormatChanged: (format: '12h' | '24h') => {
    safeTrack('Time Format Changed', { format });
  },
  clockModeChanged: (mode: 'clock' | 'schedule') => {
    safeTrack('Clock Mode Changed', { mode });
  },
  meetingCreated: (provider: string) => {
    safeTrack('Meeting Created', { provider });
  },
  voiceModeEngaged: () => {
    safeTrack('Voice Mode Engaged');
  },
  voicePermissionRequested: () => {
    safeTrack('Voice Permission Requested');
  },
  voicePermissionGranted: () => {
    safeTrack('Voice Permission Granted');
  },
  voicePermissionDenied: () => {
    safeTrack('Voice Permission Denied');
  },
  voiceModeUsed: (action: string) => {
    safeTrack('Voice Mode Used', { action });
  }
}; 