'use client'

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { useCitiesStore } from "@/store/citiesStore"
import { useClockSettingsStore, useClockStore } from "@/store/timeStore"
import { useMemo, useState } from "react"
import { getTimeCategory, TIME_COLORS } from "@/lib/meetingUtils"
import { CreateMeetingButtonComponent } from './create-meeting-button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Home, MoreVertical, X } from "lucide-react"
import { trackEvent } from "@/lib/analytics"
import { TimezoneData } from "@/api/timezone/timezoneData"
import { isSameCity } from "@/lib/cityUtils"

function formatCityName(data: TimezoneData): string {
  // Ensure we have required fields
  if (!data.city || !data.country) {
    console.error('Missing city or country data:', data);
    return 'Unknown Location';
  }

  // For countries, just show the name
  if (!data.city.includes(' ') && data.city === data.country) {
    return data.city;
  }

  // For cities, include state/country for disambiguation
  const parts = [];
  parts.push(data.city);

  // Add state if available
  if (data.state) {
    parts.push(data.state);
  }

  // Always add country for cities
  parts.push(data.country);

  return parts.join(', ');
}

function AddLocalCityButton() {
  const { cities, addLocalCity, geolocatedCity } = useCitiesStore();
  const [isLoading, setIsLoading] = useState(false);

  // Don't show if we don't have geolocation data yet
  if (!geolocatedCity) {
    return null;
  }

  // Check if the city is already in the list using isSameCity
  const isAlreadyAdded = Array.from(cities.values()).some(city =>
    isSameCity(city, geolocatedCity)
  );

  // Don't show if the city is already in the list
  if (isAlreadyAdded) {
    return null;
  }

  const handleAddLocalCity = async () => {
    setIsLoading(true);
    try {
      await addLocalCity();
      if (geolocatedCity) {
        trackEvent.cityAdded(geolocatedCity.locationKey);
      }
    } catch (error) {
      console.error('Failed to add local city:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant="ghost"
      size="sm"
      className="w-full mt-2 flex items-center justify-start gap-2 text-muted-foreground hover:text-foreground"
      onClick={handleAddLocalCity}
      disabled={isLoading}
      data-testid="add-local-city-button"
    >
      <span className="text-lg" aria-hidden="true">+</span>
      <span className="text-lg" aria-hidden="true">{geolocatedCity.emoji}</span>
      <span>
        {isLoading ? 'Adding...' : geolocatedCity.city}
      </span>
    </Button>
  );
}

export function CreateMeetingCard() {
  const { cities, homeCity, removeCity, setHomeCity } = useCitiesStore();
  const { timeFormat } = useClockSettingsStore();
  const { getAdjustedTime } = useClockStore();

  const homeTimezone = homeCity ? cities.get(homeCity)?.timezone : null;
  const homeData = homeCity ? cities.get(homeCity) : null;

  // Create memoized array of other cities
  const otherCities = useMemo(() => {
    return Array.from(cities.values())
      .filter(city => city.locationKey !== homeCity);
  }, [cities, homeCity]);

  if (!homeCity || !homeTimezone || !homeData) {
    return null;
  }

  function removeCityFromStore(locationKey: string): void {
    trackEvent.cityRemoved(locationKey);
    removeCity(locationKey);
  }

  const getBackgroundColorForTime = (date: Date) => {
    const hour = date.getHours()
    const category = getTimeCategory(hour)
    return TIME_COLORS[category]
  }

  const getTimeForCity = (timezone: string) => {
    const adjustedTime = getAdjustedTime()
    return new Date(adjustedTime.toLocaleString('en-US', { timeZone: timezone }))
  }

  const homeTime = homeTimezone ? getTimeForCity(homeTimezone) : null

  return (
    <Card className="w-full max-w-md mx-auto shadow-lg mt-4">
      <CardContent className="p-4">
        {/* Home city */}
        <div className="flex items-center justify-between pb-2" data-testid="city-card">
          <div className="flex items-center gap-3">
            <div
              className="w-10 h-10 rounded-full flex items-center justify-center ring-1 ring-primary/20"
              style={{
                backgroundColor: homeTime ? getBackgroundColorForTime(homeTime) : undefined,
                opacity: 0.8
              }}
            >
              <span className="text-xl" aria-hidden="true">{homeData.emoji}</span>
            </div>
            <div>
              <div className="text-2xl font-['SofiaSansRegular'] font-bold tracking-tight tabular-nums">
                {homeTime?.toLocaleTimeString('en-US', timeFormat)}
              </div>
              <div className="text-lg font-['SofiaSansRegular'] text-muted-foreground">
                {formatCityName(homeData)}
              </div>
            </div>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="h-8 w-8">
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={() => removeCityFromStore(homeCity)}
                className="text-red-600 focus:text-red-600 focus:bg-red-50"
              >
                <X className="mr-2 h-4 w-4" />
                Remove city
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <Separator className="my-2" />

        {/* Other cities */}
        <div className="space-y-2">
          {otherCities.map((city) => {
            const cityTime = getTimeForCity(city.timezone)
            return (
              <div key={city.locationKey} className="flex items-center gap-2 group" data-testid="city-card">
                <div
                  className="w-8 h-8 rounded-full flex items-center justify-center"
                  style={{
                    backgroundColor: getBackgroundColorForTime(cityTime),
                    opacity: 0.8
                  }}
                >
                  <span className="text-lg" aria-hidden="true">{city.emoji}</span>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="flex flex-col sm:flex-row sm:items-center gap-0.5 sm:gap-2">
                    <span className="text-lg font-['SofiaSansRegular'] tabular-nums whitespace-nowrap">
                      {cityTime.toLocaleTimeString('en-US', timeFormat)}
                    </span>
                    <span className="text-base font-['SofiaSansRegular'] text-muted-foreground truncate">
                      {formatCityName(city)}
                    </span>
                  </div>
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon" className="h-6 w-6">
                      <MoreVertical className="h-3 w-3" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={() => {
                      trackEvent.homeCitySet(city.locationKey);
                      setHomeCity(city.locationKey);
                    }}>
                      <Home className="mr-2 h-4 w-4" />
                      Set as home city
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => removeCityFromStore(city.locationKey)}
                      className="text-red-600 focus:text-red-600 focus:bg-red-50"
                    >
                      <X className="mr-2 h-4 w-4" />
                      Remove city
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )
          })}
        </div>

        {/* Add local city button */}
        {cities.size > 0 && <AddLocalCityButton />}
      </CardContent>
      <CardFooter className="p-4 pt-2">
        <CreateMeetingButtonComponent />
      </CardFooter>
    </Card>
  )
}