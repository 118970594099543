import React, { useState, useRef, useMemo } from 'react';
import Clock from '.';
import { useCitiesStore } from '@/store/citiesStore';
import { TimezoneData } from '@/api/timezone/timezoneData';
import {
  timeSegments,
  determineOuterSegmentColors
} from '@/lib/meetingUtils';
import { ClockBackground } from './ClockBackground';

export default function GradientClock() {
  const { cities, homeCity } = useCitiesStore();
  const homeTimezone = (homeCity && cities.get(homeCity)?.timezone) || 'UTC';

  // State to track the selected minute
  const [selectedMinute, setSelectedMinute] = useState<number | null>(null);

  // Ref to the clock container
  const clockRef = useRef<HTMLDivElement>(null);

  // Memoize the outer ring calculations and gradient
  const { outerGradient } = useMemo(() => {
    const citiesRecord = Object.fromEntries(cities) as Record<string, TimezoneData>;
    const segmentColors = determineOuterSegmentColors(
      citiesRecord,
      timeSegments,
      homeTimezone
    );

    // Generate outer ring gradient
    let gradient = 'conic-gradient(from 180deg, ';
    const segments = timeSegments.map((segment) => {
      const key = `${segment.startMinute}-${segment.endMinute}`;
      const color = segmentColors[key] || '#E0E0E0';
      const startDegree = (segment.startMinute / (24 * 60)) * 360;
      const endDegree = (segment.endMinute / (24 * 60)) * 360;
      return { color, startDegree, endDegree };
    });

    // Merge adjacent segments of the same color
    const mergedSegments = segments.reduce((acc, segment) => {
      const lastSegment = acc[acc.length - 1];
      if (lastSegment && lastSegment.color === segment.color) {
        lastSegment.endDegree = segment.endDegree;
      } else {
        acc.push({ ...segment });
      }
      return acc;
    }, [] as typeof segments);

    gradient += mergedSegments
      .map(
        ({ color, startDegree, endDegree }) =>
          `${color} ${startDegree}deg, ${color} ${endDegree}deg`
      )
      .join(', ');

    gradient += ')';

    return { outerGradient: gradient };
  }, [cities, homeTimezone]);

  // Function to calculate the minute based on touch/mouse position
  const calculateMinute = (clientX: number, clientY: number): number | null => {
    const rect = clockRef.current?.getBoundingClientRect();
    if (!rect) return null;

    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    const dx = clientX - centerX;
    const dy = clientY - centerY;

    const distance = Math.sqrt(dx * dx + dy * dy);
    const radius = rect.width / 2;

    if (distance > radius) return null; // Outside the clock

    let angle = Math.atan2(dy, dx) * (180 / Math.PI); // Convert to degrees
    angle = (angle + 360 + 90) % 360; // Adjust so 0deg is at top

    const minute = Math.floor((angle / 360) * 24 * 60) % (24 * 60); // Convert angle to minutes

    return minute;
  };

  // Event handlers for touch and mouse interactions
  const handlePointerDown = (clientX: number, clientY: number) => {
    const minute = calculateMinute(clientX, clientY);
    if (minute !== null) {
      setSelectedMinute(minute);
    }
  };

  const handlePointerMove = (clientX: number, clientY: number) => {
    const minute = calculateMinute(clientX, clientY);
    if (minute !== null && minute !== selectedMinute) {
      setSelectedMinute(minute);
    }
  };

  const onTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    handlePointerDown(touch.clientX, touch.clientY);
  };

  const onTouchMove = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    handlePointerMove(touch.clientX, touch.clientY);
  };

  const onTouchEnd = () => {
    // Optional: Implement any touch end logic
  };

  const onMouseDown = (e: React.MouseEvent) => {
    handlePointerDown(e.clientX, e.clientY);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  const onMouseMove = (e: MouseEvent) => {
    handlePointerMove(e.clientX, e.clientY);
  };

  const onMouseUp = () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
  };

  return (
    <Clock
      ClockBackgroundComponent={
        <>
          {/* Gradient Background */}
          <div
            id="gradient-clock-background"
            className="absolute"
            style={{
              background: outerGradient,
              borderRadius: '50%',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              mixBlendMode: 'multiply',
              width: '120%',
              height: '120%',
              zIndex: -1,
            }}
          />
          <div
            className="absolute inset-0"
            style={{
              filter: "drop-shadow(0em 0.2em 0.2em rgba(112, 100, 93, 0.75))",
            }}
          >
            <ClockBackground />
          </div>
          <div
            ref={clockRef}
            className="absolute inset-0"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onMouseDown={onMouseDown}
          />
        </>
      }
    />
  );
} 