import React from 'react';
import { CityClock } from './CityClock';
import { ClockRing } from './ClockRing';
import { useCitiesStore } from '@store/citiesStore';
import { useClockStore } from '@store/timeStore';
import { TimezoneData } from '@/api/timezone/timezoneData';

function getTimeForCity(timezone: string, baseTime: Date): Date {
  return new Date(baseTime.toLocaleString('en-US', { timeZone: timezone }));
}

function calculateAngle(cityTime: Date): number {
  const hours = cityTime.getHours();
  const minutes = cityTime.getMinutes();
  return ((hours + minutes / 60) / 24) * 360;
}

function getOffsetHours(city: TimezoneData, baseTime: Date): number {
  const cityTime = getTimeForCity(city.timezone, baseTime);
  const offsetMs = cityTime.getTime() - baseTime.getTime();
  return offsetMs / (1000 * 60 * 60);
}

export function CityClocks() {
  const { getAdjustedTime } = useClockStore();
  const { cities, homeCity } = useCitiesStore();
  const baseTime = getAdjustedTime();

  // The minimum hour difference required to create a new group of cities
  // Cities with time differences less than this will be grouped together
  const MIN_HOURS_FOR_NEW_GROUP = 1.1;

  // Group cities within ~1 hour of each other and sort by offset
  // This helps prevent visual clutter when cities have similar times
  const groupedCities = (() => {
    const sortedCities = Array.from(cities.values()).sort((a, b) =>
      getOffsetHours(a, baseTime) - getOffsetHours(b, baseTime)
    );
    const groups: TimezoneData[][] = [];

    sortedCities.forEach(city => {
      const offset = getOffsetHours(city, baseTime);
      const lastGroup = groups[groups.length - 1];
      if (groups.length === 0 ||
        Math.abs(offset - getOffsetHours(lastGroup[0], baseTime)) >= MIN_HOURS_FOR_NEW_GROUP) {
        groups.push([city]);
      } else {
        groups[groups.length - 1].push(city);
      }
    });

    return groups;
  })();

  // For single ring mode, use a fixed radius of 43% (matches inset-[14%] from ClockBackground)
  const singleRingRadius = 43;

  return (
    <ClockRing>
      {groupedCities.map((citiesGroup, index) => {
        // Calculate average offset for the group
        const avgOffset = citiesGroup.reduce((sum, city) =>
          sum + getOffsetHours(city, baseTime), 0
        ) / citiesGroup.length;

        // Calculate time based on average offset
        const avgTime = new Date(baseTime.getTime() + avgOffset * 60 * 60 * 1000);
        const angle = calculateAngle(avgTime);

        return (
          <CityClock
            key={`group-${index}`}
            cities={citiesGroup}
            angle={angle}
            baseTime={baseTime}
            homeCity={homeCity}
            radius={singleRingRadius}
          />
        );
      })}
    </ClockRing>
  );
}
