import { z } from 'zod';

/**
 * Location Key Format
 * 
 * Location keys are unique identifiers for locations in the format:
 * - For cities with state: "cityname-state-countrycode" (e.g., "winston-salem-nc-us")
 * - For cities without state: "cityname-countrycode" (e.g., "london-gb")
 * - For countries: "countrycode" (e.g., "kw")
 * 
 * Rules:
 * - All parts are lowercase
 * - Only letters and hyphens allowed
 * - City and state names can contain hyphens
 * - Country code is always 2 letters
 */

export const locationKeySchema = z.string()
  .min(1, "Location key is required")
  .max(100, "Location key is too long")
  .regex(/^[a-z-]+$/, "Location key must be lowercase with only letters and hyphens");

/**
 * Normalizes a string for use in location keys
 * - Converts to lowercase
 * - Replaces spaces and special characters with hyphens
 * - Removes duplicate hyphens
 */
function normalizeKeyPart(part: string): string {
  return part
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '-') // Replace non-alphanumeric with hyphens
    .replace(/-+/g, '-')        // Replace multiple hyphens with single hyphen
    .replace(/^-|-$/g, '');     // Remove leading/trailing hyphens
}

/**
 * Generates a location key from city, state, and country information
 * @param country Country code (required)
 * @param city City name (optional)
 * @param state State/province code (optional)
 * @returns A properly formatted location key
 */
export function generateLocationKey(country: string, city?: string, state?: string): string {
  const countryKey = normalizeKeyPart(country);

  if (!city) return countryKey;

  const cityKey = normalizeKeyPart(city);

  if (!state) return `${cityKey}-${countryKey}`;

  const stateKey = normalizeKeyPart(state);
  return `${cityKey}-${stateKey}-${countryKey}`;
}

/**
 * Validates a location key format
 * @param locationKey The location key to validate
 * @returns True if the location key is valid
 */
export function isValidLocationKey(locationKey: string): boolean {
  return locationKeySchema.safeParse(locationKey).success;
} 