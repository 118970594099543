'use client';

import { useClockSettingsStore, useClockStore } from "@store/timeStore";
import { RotateCcw } from "lucide-react";

export default function ResetOffsetButton() {
  const { resetBaseTime } = useClockSettingsStore();
  const { isAdjusted } = useClockStore();

  if (!isAdjusted) return null;

  return (
    <button
      onClick={resetBaseTime}
      className="rounded-full p-1.5 bg-blue-100 hover:bg-blue-200 transition-colors"
      aria-label="Reset to local time"
    >
      <RotateCcw className="w-4 h-4 text-blue-600" />
    </button>
  );
}