import { TimezoneData } from "@/api/timezone/timezoneData"

const HOURS_IN_DAY = 24
const MINUTES_IN_HOUR = 60
const SEGMENT_SIZE = 15 // 15-minute segments
const SEGMENTS_IN_DAY = (HOURS_IN_DAY * MINUTES_IN_HOUR) / SEGMENT_SIZE
export const DEGREES_PER_HOUR = 360 / HOURS_IN_DAY
export const DEGREES_PER_SEGMENT = 360 / SEGMENTS_IN_DAY

export enum TimeCategory {
  NIGHT = 'NIGHT',      // 21:00-07:00
  WORK = 'WORK',        // 08:00-17:00
  TRANSITION = 'TRANSITION'  // 07:00-08:00 and 17:00-21:00
}

export const TIME_COLORS = {
  [TimeCategory.NIGHT]: '#F2BFC4',      // Red for night
  [TimeCategory.WORK]: '#B3CEBA',       // Green for work
  [TimeCategory.TRANSITION]: '#E8DFD6',  // Yellow for transition
  DEFAULT: '#E0E0E0'                    // Light Gray as fallback
} as const

export interface TimeSegment {
  startMinute: number
  endMinute: number
  category: TimeCategory
}

export const getTimeCategory = (hour: number): TimeCategory => {
  if (hour >= 8 && hour <= 17) return TimeCategory.WORK
  if (hour >= 21 || hour <= 6) return TimeCategory.NIGHT
  return TimeCategory.TRANSITION
}

export const timeSegments: TimeSegment[] = Array.from(
  { length: SEGMENTS_IN_DAY },
  (_, index) => {
    const startMinute = index * SEGMENT_SIZE;
    const endMinute = startMinute + SEGMENT_SIZE;
    const hour = Math.floor(startMinute / MINUTES_IN_HOUR);
    return {
      startMinute,
      endMinute: endMinute % (HOURS_IN_DAY * MINUTES_IN_HOUR),
      category: getTimeCategory(hour)
    };
  }
);

export const getColorForCategory = (category: TimeCategory): string => {
  return TIME_COLORS[category]
}

export const getTimeForCity = (timezone: string, getAdjustedTime: () => Date): Date => {
  const adjustedTime = getAdjustedTime()
  const cityTime = new Date(adjustedTime.toLocaleString('en-US', { timeZone: timezone }))
  return cityTime
}

export const getTimezoneOffset = (
  timezone: string,
  baseTimezone: string,
  utcDate?: Date,
  baseDate?: Date
): number => {
  const now = new Date();
  const actualUtcDate = utcDate || new Date(now.toLocaleString('en-US', { timeZone: 'UTC' }));
  const actualBaseDate = baseDate || new Date(now.toLocaleString('en-US', { timeZone: baseTimezone }));

  try {
    const cityDate = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
    const cityOffset = (cityDate.getTime() - actualUtcDate.getTime()) / (1000 * 60 * 60);
    const baseOffset = (actualBaseDate.getTime() - actualUtcDate.getTime()) / (1000 * 60 * 60);
    return cityOffset - baseOffset;
  } catch (error) {
    console.error('Error calculating timezone offset:', error);
    return 0;
  }
}

export const determineOuterSegmentColors = (
  cities: Record<string, TimezoneData>,
  timeSegments: TimeSegment[],
  baseTimezone: string
): Record<string, string> => {
  const segmentColors: Record<string, string> = {}
  const now = new Date();
  const utcDate = new Date(now.toLocaleString('en-US', { timeZone: 'UTC' }));
  const baseDate = new Date(now.toLocaleString('en-US', { timeZone: baseTimezone }));

  try {
    timeSegments.forEach(segment => {
      const activeCategories: TimeCategory[] = []

      Object.values(cities).forEach(city => {
        const offset = getTimezoneOffset(city.timezone, baseTimezone, utcDate, baseDate)
        const offsetMinutes = offset * MINUTES_IN_HOUR
        let cityMinute = (segment.startMinute + offsetMinutes) % (HOURS_IN_DAY * MINUTES_IN_HOUR)
        cityMinute = cityMinute < 0 ? cityMinute + (HOURS_IN_DAY * MINUTES_IN_HOUR) : cityMinute
        const cityHour = Math.floor(cityMinute / MINUTES_IN_HOUR)
        activeCategories.push(getTimeCategory(cityHour))
      })

      const key = `${segment.startMinute}-${segment.endMinute}`

      // Priority: NIGHT > TRANSITION > WORK
      if (activeCategories.length === 0) {
        segmentColors[key] = TIME_COLORS.DEFAULT
      } else if (activeCategories.includes(TimeCategory.NIGHT)) {
        segmentColors[key] = TIME_COLORS[TimeCategory.NIGHT]
      } else if (activeCategories.includes(TimeCategory.TRANSITION)) {
        segmentColors[key] = TIME_COLORS[TimeCategory.TRANSITION]
      } else if (activeCategories.includes(TimeCategory.WORK)) {
        segmentColors[key] = TIME_COLORS[TimeCategory.WORK]
      } else {
        segmentColors[key] = TIME_COLORS.DEFAULT
      }
    })

    return segmentColors
  } catch (error) {
    console.error('Error determining outer segment colors:', error);
    return {}
  }
} 