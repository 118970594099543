'use client';

import React, { useState } from 'react';

const FeedbackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7.5 8.25H16.5M7.5 11.25H12M2.25 12.76C2.25 14.36 3.373 15.754 4.957 15.987C6.086 16.153 7.227 16.28 8.38 16.366C8.73 16.392 9.05 16.576 9.245 16.867L12 21L14.755 16.867C14.8516 16.7233 14.9798 16.6034 15.1297 16.5166C15.2795 16.4298 15.4472 16.3783 15.62 16.366C16.7652 16.2805 17.9069 16.1541 19.043 15.987C20.627 15.754 21.75 14.361 21.75 12.759V6.741C21.75 5.139 20.627 3.746 19.043 3.513C16.711 3.17072 14.357 2.99926 12 3C9.608 3 7.256 3.175 4.957 3.513C3.373 3.746 2.25 5.14 2.25 6.741V12.759V12.76Z"
      stroke="#45494D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FeedbackButton = ({ onClick, children }: { onClick?: () => void; children: React.ReactNode }) => (
  <button
    onClick={onClick}
    className="font-['SofiaSansRegular'] text-[#45494D]"
    style={{
      display: 'inline-flex',
      height: '40px',
      padding: '6px 16px',
      alignItems: 'center',
      gap: '11px',
      flexShrink: 0,
      background: '#E8E8E8',
      borderRadius: '35px',
      border: 'none'
    }}
  >
    {children}
  </button>
);

const Toast = ({ message, isVisible, onClose }: { message: string; isVisible: boolean; onClose: () => void }) => {
  if (!isVisible) return null;

  return (
    <div className={`
      fixed top-8 left-1/2 -translate-x-1/2
      bg-white 
      px-8 py-4 
      rounded-lg 
      shadow-lg 
      font-['SofiaSansRegular'] 
      flex items-center gap-4 
      z-[200]
      animate-toast-slide-down
      [animation-fill-mode:forwards]
      text-base 
      min-w-[320px] 
      ${!isVisible && 'animate-toast-slide-up'}
    `}>
      <span className="flex-1">{message}</span>
      <button
        onClick={onClose}
        className="ml-2 opacity-50 hover:opacity-100 transition-opacity text-lg"
      >
        ✕
      </button>
    </div>
  );
};

const FeedbackModal = () => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [toast, setToast] = useState({ show: false, message: '' });

  const showToast = (message: string) => {
    setToast({ show: true, message });
    setTimeout(() => {
      setToast(prev => ({ ...prev, show: false }));
      setTimeout(() => {
        setToast({ show: false, message: '' });
      }, 300);
    }, 5000);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    if (text.length <= 2000) {
      setFeedback(text);
      setCharCount(text.length);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Additional validation before submission
    if (feedback.length === 0 || feedback.length > 2000) {
      showToast('Feedback must be between 1 and 2000 characters');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          feedback,
          timestamp: new Date().toISOString(),
          url: window.location.href,
          userAgent: navigator.userAgent
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      setFeedback('');
      setIsOpen(false);
      showToast('☺️ Thank you! Your feedback has been submitted successfully.');

    } catch (error) {
      console.error('Feedback submission error:', error);
      showToast(error instanceof Error ? error.message : 'Error submitting feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {!isOpen ? (
        <FeedbackButton onClick={() => setIsOpen(true)}>
          <FeedbackIcon />
          Feedback
        </FeedbackButton>
      ) : (
        <div className="fixed inset-0 z-[100]">
          {/* Overlay with fade in */}
          <div
            className="absolute inset-0 bg-black/50 animate-fade-in"
            onClick={() => setIsOpen(false)}
          />

          {/* Modal with fade in and scale */}
          <div className="relative min-h-screen bg-white animate-fade-in-scale">
            <div className="container mx-auto max-w-3xl p-6">
              <div className="space-y-3">
                <h2 className="text-xl font-['SofiaSansRegular']">What&apos;s on your mind?</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="space-y-2">
                    <textarea
                      value={feedback}
                      onChange={handleTextChange}
                      placeholder="Type your feedback here..."
                      className="w-full min-h-[200px] p-3 
                        border rounded-md 
                        font-['SofiaSansRegular'] 
                        resize-none 
                        focus:outline-none focus:ring-2 focus:ring-blue-500
                        transition-all duration-200"
                    />
                    <div className="text-sm text-gray-500 text-right font-['SofiaSansRegular']">
                      {charCount}/2000 characters
                    </div>
                  </div>
                  <div className="flex justify-end gap-3">
                    <button
                      type="button"
                      onClick={() => setIsOpen(false)}
                      className="px-4 py-2 
                        border rounded-md 
                        font-['SofiaSansRegular']
                        hover:bg-gray-50
                        active:bg-gray-100
                        transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting || feedback.length === 0}
                      className="px-4 py-2 
                        bg-black text-white 
                        rounded-md 
                        font-['SofiaSansRegular'] 
                        disabled:opacity-50
                        hover:bg-gray-900
                        active:bg-gray-800
                        transition-colors"
                    >
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toast
        message={toast.message}
        isVisible={toast.show}
        onClose={() => setToast({ show: false, message: '' })}
      />
    </>
  );
};

export default FeedbackModal; 