import { TimezoneData } from '@/api/timezone/timezoneData';
import { generateLocationKey } from '@/utils/locationKey';

export const initialCities = new Map<string, TimezoneData>([
  ['miami-fl-us', {
    city: 'Miami',
    timezone: 'America/New_York',
    emoji: '🇺🇸',
    abbreviation: 'Miami',
    country: 'US',
    state: 'FL',
    locationKey: generateLocationKey('US', 'miami', 'fl')
  }],
  ['melbourne-vic-au', {
    city: 'Melbourne',
    timezone: 'Australia/Melbourne',
    emoji: '🇦🇺',
    abbreviation: 'Melbourne',
    country: 'AU',
    state: 'VIC',
    locationKey: generateLocationKey('AU', 'melbourne', 'vic')
  }]
]);